.pres {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.pres-text {
  flex: 1;
  padding-right: 20px; /* Espace entre le texte et l'image */
  line-height: 1.6;
  text-align: justify;
}

.pres-info {
  flex: 0.4;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pres-info img {
  width: 150px;
  height: 200px;
  border-radius: 10px;
  margin-bottom: 20px; /* Espace entre la photo et la section d'informations */
}

.pres-info .info {
  text-align: center;
}

.pres-info h2 {
  font-size: 1.2em;
  margin: 10px 0;
}

.pres-info p {
  line-height: 1.4;
  font-size: 0.95em;
}

.info {
    margin: auto; /* Centre le contenu */
    background-color: #f9f9f9; /* Couleur de fond douce */
    border: 1px solid #ddd; /* Bordure autour du conteneur */
    border-radius: 8px; /* Coins arrondis */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Ombre légère */
}

.info h2 {
  font-size: 20px;
  color: #333;
  margin-top: 30px;
  padding-top: 10px;
  font-weight: bold;
  text-transform: uppercase;
  border-top: 3px solid #ddd; /* Ligne au-dessus de chaque section */
  padding-bottom: 8px;
}

.info h2:first-of-type {
  border-top: none; /* Pas de ligne pour le premier titre */
}

.info h3{
  border-top: 1px dashed #ddd;
  font-size: 16px;
}

.info p {
  font-size: 16px;
  color: #555;
  line-height: 1.6;
  margin-top: 10px;
  text-align: justify;
  padding-left: 10px;
}
/* Contact.css */

.contact-form {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  .contact-form label {
    margin-bottom: 8px;
    font-weight: bold;
    color: #333;
  }
  
  .contact-form input[type="email"],
  .contact-form textarea {
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .contact-form input[type="email"] {
    height: 40px;
  }
  
  .contact-form textarea {
    resize: vertical;
    font-family: inherit;
  }
  
  .contact-form button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .contact-form button:hover {
    background-color: #0056b3;
  }
  
  .contact-form button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  .linkedin-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    text-align: center;
  }
  
  .linkedin-link a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #0077b5;
    font-weight: bold;
    font-size: 16px;
  }
  
  .linkedin-icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
  
  /* Style pour l'attribution */
  .attribution {
    margin-top: 10px;
    font-size: 12px;
    color: #666;
    text-align: center;
  }
  
  .attribution a {
    color: #0077b5;
    text-decoration: none;
  }
  
  .attribution a:hover {
    text-decoration: underline;
  }

nav ul {
  background-color: #f8f9fa;
  padding: 1rem;
  border-bottom: 2px solid #ddd;
}

nav ul li a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
}

nav ul li a:hover {
  color: #007bff;
}
